<template>
	<v-col class="individual-container">
		<template v-if="users.length > 0">
			<v-row
				v-for="(user, i) in users"
				:key="i"
				:class="
					selectedUser == user.email
						? 'user-row userHover my-0 ml-2 selected'
						: 'user-row userHover my-0 ml-2'
				"
			>
				<v-col cols="12" sm="12" class="d-flex">
					<div>
						<v-avatar v-if="user.avatar">
							<img :src="user.avatar" alt="John" />
						</v-avatar>
						<v-avatar v-else color="grey">
							<span class="white--text text-h5">{{ getUserInitials(user.name) }}</span>
						</v-avatar>
					</div>
					<div @click="userSelected(user)" class="rounded-lg ml-4" style="display: flex; flex: 1;">
						<p style="margin-right: 8px; margin-top: 12px;">{{ user.name }}</p>
						<div class="dot" v-if="user.posted" style="background-color: green;"></div>
					</div>
				</v-col>
			</v-row>
		</template>
		<template v-else>
			<v-container fluid>
				<v-row>
					<v-col>
						<span class="d-flex justify-center">Loading Users...</span>
						<v-progress-linear indeterminate color="cyan"></v-progress-linear>
					</v-col>
				</v-row>

				<v-row v-for="loader in skeletonLoaders" :key="loader.key">
					<v-col>
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</v-col>
</template>

<script>
export default {
	name: "Individuals",
	props: {
		userList: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			users: [
				{ name: "Patrick Watson", posted: false },
				{ name: "Patrick Watson", posted: true },
				{ name: "Patrick Watson", posted: false },
				{ name: "Patrick Watson", posted: false },
				{ name: "Patrick Watson", posted: false },
				{ name: "Patrick Watson", posted: false },
				{ name: "Patrick Watson", posted: false },
				{ name: "Patrick Watson", posted: false },
				{ name: "Patrick Watson", posted: false },
				{ name: "Patrick Watson", posted: false }
			],
			skeletonLoaders: [
				{ key: "sl-1" },
				{ key: "sl-2" },
				{ key: "sl-3" },
				{ key: "sl-4" },
				{ key: "sl-5" },
				{ key: "sl-6" },
				{ key: "sl-7" },
				{ key: "sl-8" },
				{ key: "sl-9" },
				{ key: "sl-10" },
				{ key: "sl-11" },
				{ key: "sl-12" },
				{ key: "sl-13" },
				{ key: "sl-14" },
				{ key: "sl-15" }
			],
			selectedUser: ""
		};
	},
	methods: {
		getUserInitials(name) {
			if (name) {
				let initials = "";
				let nameParts = name.split(" ");
				nameParts.forEach(function(part) {
					initials = `${initials}${part.charAt(0)}`;
				});

				return initials;
			}
		},

		userSelected(user) {
			this.selectedUser = user.email;
			this.$emit("userSelected", user.email);
		}
	},
	watch: {
		userList: function() {
			this.users = this.userList;
		}
	},
	mounted() {
		this.users = this.userList;
	}
};
</script>

<style scoped>
.individual-container {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	flex-grow: 1;
	flex-basis: auto;
	border: none;
	margin: 0;
	padding: 0;
}

.dot {
	height: 15px;
	width: 15px;
	margin-left: 12px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}

.userHover:hover {
	background-color: #e9f1fd;
	cursor: pointer;
}

.selected {
	background-color: #e9f1fd;
}
</style>
