<template>
	<div>
		<div class="package-section-heading" style="padding-bottom: 5px;">
			<v-sheet outlined class="rounded-lg" min-height="60vh">
				<v-container fluid>
					<v-row v-for="(entry, i) in entries" :key="i" class="user-row" outlined>
						<v-divider></v-divider>
						<v-col cols="12" sm="4" style="display: flex; align-items: center;">
							<div class="px-4">
								<v-avatar v-if="hasAvatar(entry.user)">
									<img :src="getUserAvatar(entry.user)" />
								</v-avatar>
								<v-avatar v-else color="grey">
									<span class="white--text text-h5">{{ getUserInitials(entry.name) }}</span>
								</v-avatar>
							</div>
							<p style="margin-right: 8px; margin-top: 12px;">
								{{ getUserDisplayName(entry.user) }}
							</p>
						</v-col>

						<v-col cols="12" sm="8" style="display: flex; align-items: center;">
							<p>{{ entry.description }}</p>
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
		</div>
	</div>
</template>

<script>
export default {
	name: "ProjectDetails",
	props: {
		userList: {
			type: Array,
			default: () => []
		},
		projectId: {
			type: Number
		},
		date: {
			type: String
		}
	},
	data() {
		return {
			entries: []
		};
	},
	created() {
		console.log("ON CREATION");
		this.collectProjectEntries();
	},
	watch: {
		date(val) {
			console.log("dateChange", val);
			this.collectProjectEntries();
		},

		projectId(val) {
			console.log("shown value", val);
			this.collectProjectEntries();
		}
	},
	methods: {
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split("-");
			return `${month}/${day}/${year}`;
		},
		parseDate(date) {
			if (!date) return null;

			const [month, day, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},

		getUserDisplayName(email) {
			return this.userList.find(user => user.email === email)?.name;
		},

		getUserInitials(name) {
			if (name) {
				let initials = "";
				let nameParts = name.split(" ");
				nameParts.forEach(function(part) {
					initials = `${initials}${part.charAt(0)}`;
				});

				return initials;
			}
		},

		getUserAvatar(email) {
			return this.userList.find(user => user.email === email)?.avatar;
		},

		hasAvatar(email) {
			var avatar = this.userList.find(user => user.email === email)?.avatar;
			return avatar && avatar.length > 0;
		},

		async collectProjectEntries() {
			const params = {
				projectId: this.projectId,
				date: this.date
			};

			try {
				const res = await this.$http.get(process.env.VUE_APP_API_URL + "/api/daily/entries", { params });
				console.log("setting entries list to: ", res.data);
				this.entries = res.data;
			} catch (error) {
				console.log(error);
			}
		}
	}
};
</script>
