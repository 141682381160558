<template>
	<v-col class="projects-container">
		<template v-if="projects.length > 0">
			<v-row
				v-for="(project, i) in projects"
				:key="i"
				v-bind:class="{ selected: selectedIndex == i }"
				class="mx-0 projectHover"
			>
				<v-col cols="12" sm="12" class="d-flex align-baseline"
					><div>
						<v-icon large>
							mdi-folder-outline
						</v-icon>
					</div>
					<div
						class="rounded-lg px-4"
						style="display: flex; flex: 1;"
						@click="selectProject(i, project.projectId)"
					>
						<p>{{ project.projectName }}</p>
					</div></v-col
				>
			</v-row>
		</template>
	</v-col>
</template>

<script>
export default {
	name: "Projects",
	data() {
		return {
			projects: [
				{ projectId: 1, projectName: "BCC" },
				{ projectId: 2, projectName: "Better Day Health" },
				{ projectId: 3, projectName: "Chemformation" },
				{ projectId: 4, projectName: "DCS" },
				{ projectId: 5, projectName: "PPG" },
				{ projectId: 6, projectName: "Sparkfish - Internal" },
				{ projectId: 7, projectName: "Team Leader" },
				{ projectId: 8, projectName: "TXFB" },
				{ projectId: 9, projectName: "Maxx-D" }
			],
			selectedIndex: -1
		};
	},

	created() {
		this.collectProjects();
	},

	methods: {
		async collectProjects() {
			try {
				const res = await this.$http.get(process.env.VUE_APP_API_URL + `/api/daily/projects`);
				console.log("setting projects list to: ", res.data);
				if (res.data && res.data.length > 0) {
					this.projects = [...res.data];
				}
			} catch (error) {
				console.log(error);
			}
		},

		selectProject(index, projectId) {
			this.selectedIndex = index;
			console.log("EMITTING", projectId);
			this.$emit("selection", projectId);
		}
	}
};
</script>

<style scoped>
.projects-container {
	display: flex;
	flex-direction: column;
	overflow: auto;
	min-height: 85vh;
	flex-grow: 1;
	flex-basis: auto;
	border: none;
	margin: 0;
	padding: 0;
}

.dot {
	height: 15px;
	width: 15px;
	margin-left: 12px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}

.projectHover:hover {
	background-color: #e9f1fd;
	cursor: pointer;
}

.selected {
	background-color: #e9f1fd;
}
</style>
