<template>
	<v-row class="pt-4" style="height:auto">
		<v-col
			cols="12"
			sm="2"
			class="px-0 py-0"
			:style="$store.getters.isMobile ? 'min-width:-webkit-fill-available' : ''"
		>
			<Sidebar @projectSelection="showProject" :userList="users" @userSelected="userSelected" />
		</v-col>

		<v-col cols="12" :sm="$store.getters.isMobile ? '12' : '10'" class="primary-container">
			<v-container fluid class="entry-container">
				<v-row class="align-center">
					<v-col cols="12" sm="8" class="py-0">
						<div :class="$store.getters.isMobile ? 'text-center' : 'float-left'">
							<h1>Daily 1-2-3</h1>
						</div>
					</v-col>
					<v-col cols="12" sm="4" class="py-0">
						<v-row class="align-center">
							<v-col cols="12" sm="8" class="py-0">
								<div :class="$store.getters.isMobile ? 'date-picker' : 'float-right'">
									<v-menu
										ref="menu1"
										v-model="menu1"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="dateFormatted"
												prepend-icon="mdi-calendar"
												v-bind="attrs"
												@blur="date = parseDate(dateFormatted)"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="date"
											no-title
											@input="menu1 = false"
										></v-date-picker>
									</v-menu>
								</div>
							</v-col>
							<v-col v-if="!$store.getters.isMobile" cols="6" sm="1" class="py-0">
								<v-btn icon @click="decreaseCalendar">
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
							</v-col>
							<v-col
								v-if="!$store.getters.isMobile"
								:style="!isToday ? 'visibility: visible;' : 'visibility: hidden;'"
								cols="6"
								sm="1"
								class="py-0"
							>
								<v-btn icon @click="increaseCalendar" class="float-right">
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-col>
							<v-col v-if="isUserSelf" cols="12" sm="2" class="py-0">
								<div style="float: right; padding-top: 12px; margin-left: 12px">
									<v-btn
										v-show="
											!$store.getters.isMobile ||
												($store.getters.isMobile && !doesNotHaveEntries)
										"
										:fab="$store.getters.isMobile"
										:fixed="$store.getters.isMobile"
										:bottom="$store.getters.isMobile"
										:right="$store.getters.isMobile"
										class="primary"
										:disabled="doesNotHaveEntries"
										@click="generatePreview"
										>Post</v-btn
									>
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="12">
						<div v-if="detailsShown">
							<a class="clear-btn" @click="detailsShown = false"> Back to Entry </a>
						</div>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="12">
						<ProjectDetails
							v-if="detailsShown"
							:projectId="selectedProjectId"
							:date="dateFormatted"
							:userList="users"
						/>
						<div v-else class="package-section-heading" style="padding-bottom: 5px">
							<v-sheet outlined class="rounded-lg" min-height="10vh">
								<v-container fluid>
									<v-row class="align-baseline">
										<v-col cols="12" sm="6">
											<v-combobox
												v-model="entry1"
												label="1. Today's highest priority"
												:items="
													incomplete.filter(i => {
														return (
															i.entryId !== entry2.entryId &&
															i.entryId !== entry3.entryId
														);
													})
												"
												item-text="description"
												item-value="entryId"
												outlined
												dense
												hide-details="auto"
												:disabled="disableEntry || !isUserSelf"
												return-object
												@input="selection => onInput(selection, 1)"
											>
											</v-combobox>
										</v-col>
										<v-col cols="12" sm="2"
											><v-select
												v-model="entry1.projectId"
												:items="projectList"
												item-value="projectId"
												item-text="projectName"
												label="Select a Project"
												outlined
												dense
												hide-details="auto"
												:disabled="disableEntry || entry1.entryId != null || !isUserSelf"
												@input="e => (entryProjectId = e)"
												:rules="[
													() =>
														!!entry1.projectId || 'The associated project is required'
												]"
											></v-select
										></v-col>
										<v-col cols="12" sm="4">
											<v-stepper v-model="entry1.step" class="elevation-0">
												<v-stepper-header>
													<v-stepper-step
														edit-icon="mdi-rocket-launch"
														editable
														:complete="entry1.step >= 1"
														step="1"
														@click="entry1.step = 1"
														color="#35bfc3"
													>
														Ignition
													</v-stepper-step>

													<v-divider></v-divider>

													<v-stepper-step
														edit-icon="mdi-play"
														editable
														:complete="entry1.step >= 2"
														step="2"
														@click="entry1.step = 2"
														color="#35bfc3"
													>
														In Progress
													</v-stepper-step>

													<v-divider></v-divider>

													<v-stepper-step
														edit-icon="mdi-flag-checkered"
														editable
														:complete="entry1.step >= 3"
														step="3"
														@click="entry1.step = 3"
														color="#35bfc3"
													>
														{{ "Finalizing" }}
													</v-stepper-step>
												</v-stepper-header>
											</v-stepper>
										</v-col>
									</v-row>

									<div v-if="$store.getters.isMobile" class="mobile-div">
										<v-divider></v-divider>
									</div>

									<v-row class="align-baseline">
										<v-col cols="12" sm="6">
											<v-combobox
												v-model="entry2"
												label="2. Today's second highest priority"
												:items="
													incomplete.filter(i => {
														return (
															i.entryId !== entry1.entryId &&
															i.entryId !== entry3.entryId
														);
													})
												"
												item-text="description"
												item-value="entryId"
												outlined
												dense
												hide-details="auto"
												:disabled="disableEntry || !isUserSelf"
												@input="selection => onInput(selection, 2)"
											>
											</v-combobox>
										</v-col>
										<v-col cols="12" sm="2"
											><v-select
												v-model="entry2.projectId"
												:items="projectList"
												item-value="projectId"
												item-text="projectName"
												label="Select a Project"
												outlined
												dense
												hide-details="auto"
												:disabled="disableEntry || entry2.entryId != null || !isUserSelf"
												@input="e => (entryProjectId = e)"
												:rules="[
													() =>
														!!entry2.projectId || 'The associated project is required'
												]"
											></v-select
										></v-col>
										<v-col cols="12" sm="4">
											<v-stepper v-model="entry2.step" class="elevation-0">
												<v-stepper-header>
													<v-stepper-step
														edit-icon="mdi-rocket-launch"
														editable
														:complete="entry2.step >= 1"
														step="1"
														@click="entry2.step = 1"
														color="#35bfc3"
													>
														Ignition
													</v-stepper-step>

													<v-divider></v-divider>

													<v-stepper-step
														edit-icon="mdi-play"
														editable
														:complete="entry2.step >= 2"
														step="2"
														@click="entry2.step = 2"
														color="#35bfc3"
													>
														In Progress
													</v-stepper-step>

													<v-divider></v-divider>

													<v-stepper-step
														edit-icon="mdi-flag-checkered"
														editable
														:complete="entry2.step >= 3"
														step="3"
														@click="entry2.step = 3"
														color="#35bfc3"
													>
														{{ "Finalizing" }}
													</v-stepper-step>
												</v-stepper-header>
											</v-stepper>
										</v-col>
									</v-row>

									<div v-if="$store.getters.isMobile" class="mobile-div">
										<v-divider></v-divider>
									</div>

									<v-row class="align-baseline">
										<v-col cols="12" sm="6">
											<v-combobox
												v-model="entry3"
												label="3. Today's third highest priority"
												:items="
													incomplete.filter(i => {
														return (
															i.entryId !== entry1.entryId &&
															i.entryId !== entry2.entryId
														);
													})
												"
												item-text="description"
												item-value="entryId"
												outlined
												dense
												hide-details="auto"
												:disabled="disableEntry || !isUserSelf"
												@input="selection => onInput(selection, 3)"
											>
											</v-combobox>
										</v-col>
										<v-col cols="12" sm="2"
											><v-select
												v-model="entry3.projectId"
												:items="projectList"
												item-value="projectId"
												item-text="projectName"
												label="Select a Project"
												outlined
												dense
												hide-details="auto"
												:disabled="disableEntry || entry3.entryId != null || !isUserSelf"
												@input="e => (entryProjectId = e)"
												:rules="[
													() =>
														!!entry3.projectId || 'The associated project is required'
												]"
											></v-select
										></v-col>
										<v-col cols="12" sm="4">
											<v-stepper v-model="entry3.step" class="elevation-0">
												<v-stepper-header>
													<v-stepper-step
														edit-icon="mdi-rocket-launch"
														editable
														:complete="entry3.step >= 1"
														step="1"
														@click="entry3.step = 1"
														color="#35bfc3"
													>
														Ignition
													</v-stepper-step>

													<v-divider></v-divider>

													<v-stepper-step
														edit-icon="mdi-play"
														editable
														:complete="entry3.step >= 2"
														step="2"
														@click="entry3.step = 2"
														color="#35bfc3"
													>
														In Progress
													</v-stepper-step>

													<v-divider></v-divider>

													<v-stepper-step
														edit-icon="mdi-flag-checkered"
														editable
														:complete="entry3.step >= 3"
														step="3"
														@click="entry3.step = 3"
														color="#35bfc3"
													>
														{{ "Finalizing" }}
													</v-stepper-step>
												</v-stepper-header>
											</v-stepper>
										</v-col>
									</v-row>
								</v-container>
							</v-sheet>
						</div>
					</v-col>
				</v-row>
			</v-container>

			<v-container fluid class="past-items-container" v-if="isUserSelf && incomplete.length > 0">
				<v-row>
					<v-col cols="12" sm="12">
						<div style="margin-top: 15px" v-if="!detailsShown">
							<div style="float: left">
								<h1>Open Items</h1>
							</div>

							<div
								v-if="isUserSelf && hasOpenItemsSelected && !$store.getters.isMobile"
								style="float: right"
							>
								<v-btn style="margin-right: 8px;" @click="removeSelections">Archive</v-btn>
								<v-btn @click="completeSelections">Completed</v-btn>
							</div>

							<div v-if="$store.getters.isMobile && hasOpenItemsSelected">
								<v-speed-dial v-model="fab" fixed bottom right direction="top" transition>
									<template v-slot:activator>
										<v-btn v-model="fab" color="blue darken-2" dark fab>
											<v-icon v-if="fab">
												mdi-close
											</v-icon>
											<v-icon v-else>
												mdi-pencil
											</v-icon>
										</v-btn>
									</template>
									<v-btn fab dark small color="green" @click="completeSelections">
										<v-icon>mdi-check</v-icon>
									</v-btn>
									<v-btn fab dark small color="#e48b3c" @click="removeSelections">
										<v-icon>mdi-archive</v-icon>
									</v-btn>
								</v-speed-dial>
							</div>
						</div>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="12">
						<div style="padding-bottom: 5px" v-if="!detailsShown">
							<v-card
								outlined
								class="rounded-lg past-items"
								min-height="10vh"
								:height="$store.getters.isMobile ? '60vh' : '40vh'"
								style="padding: 12px"
							>
								<v-container fluid>
									<v-list>
										<template v-for="(incompleteItem, i) in incomplete">
											<v-divider :key="i"></v-divider>

											<v-list-item :key="'entry-' + incompleteItem.entryId">
												<v-row outlined class="align-center">
													<v-col cols="12" sm="6">
														<v-checkbox
															v-model="incompleteItem.selected"
															:label="incompleteItem.description"
														></v-checkbox>
													</v-col>
													<v-col cols="12" sm="6">
														<v-progress-linear
															:value="getProgressValue(incompleteItem.postedTime)"
															:color="getProgressColor(incompleteItem.postedTime)"
															height="25"
															style="border-radius:5px"
														>
															<template v-slot:default="{}">
																<strong
																	>{{ incompleteItem.postedTime }} Days in
																	Progress</strong
																>
															</template>
														</v-progress-linear>
													</v-col>
												</v-row>
											</v-list-item>
										</template>
									</v-list>
								</v-container>
							</v-card>
						</div>
					</v-col>
				</v-row>
			</v-container>

			<template>
				<v-dialog
					v-model="confirmDialog"
					width="500"
					:fullscreen="$store.getters.isMobile"
					style="z-index:99999999"
				>
					<v-card>
						<v-card-title class="text-h5 grey lighten-2">
							Preview Post
						</v-card-title>

						<v-card-text class="text-h6 py-4">
							<pre id="previewText" style="white-space:pre-line;">{{ preview }}</pre>
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-btn class="ma-2" @click="copyToClipboard">
								Copy to Clipboard
								<v-icon right dark>
									mdi-content-copy
								</v-icon>
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn text @click="confirmDialog = false">
								Cancel
							</v-btn>
							<v-btn color="primary" text @click="postDaily">
								Submit
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</template>

			<template>
				<v-snackbar v-model="toast.show" timeout="4500">
					{{ toast.message }}

					<template v-slot:action="{ attrs }">
						<v-btn color="#35bfc3" text v-bind="attrs" @click="toast.show = false">
							Close
						</v-btn>
					</template>
				</v-snackbar>
			</template>
		</v-col>
	</v-row>
</template>

<script>
import Sidebar from "@/components/group_info/Sidebar.vue";
import ProjectDetails from "@/components/group_info/ProjectDetails.vue";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

export default {
	name: "Daily123",
	components: {
		Sidebar,
		ProjectDetails
	},
	data: vm => ({
		incomplete: [],
		entry1: { description: "", step: 1 },
		entry2: { description: "", step: 1 },
		entry3: { description: "", step: 1 },
		entryProjectId: null,
		projectList: [],
		date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
		dateFormatted: vm.formatDate(
			new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
		),
		menu1: false,
		detailsShown: false,
		disableEntry: false,
		selectedProjectId: 0,
		users: [],
		isToday: false,
		isUserSelf: true,
		dailySubmitted: false,
		fab: false,
		confirmDialog: false,
		preview: null,
		paramList: [],
		toast: { show: false, message: null, color: null },
		msalConfig: {
			auth: {
				clientId: "96d2671a-79de-473c-9de2-08b84b6ec545",
				authority: "https://login.microsoftonline.com/1d0fd13b-b5e1-4ed7-b396-8604e91fa331/"
			},
			cache: {
				cacheLocation: "localStorage"
			}
		},
		userConfig: {
			email: "",
			slackId: "",
			slackToken: "",
			user: ""
		},
		bypassDialog: false
	}),
	computed: {
		computedDateFormatted() {
			return this.formatDate(this.date);
		},
		doesNotHaveEntries() {
			return this.entry1.projectId == null && this.entry2.projectId == null && this.entry3.projectId == null;
		},
		hasOpenItemsSelected() {
			return this.incomplete.filter(i => i.selected === true).length > 0;
		}
	},
	watch: {
		dateFormatted() {
			this.handleDates();
		}
	},
	methods: {
		async getProjectsList() {
			try {
				const res = await this.$http.get(process.env.VUE_APP_API_URL + `/api/daily/projects`);
				this.projectList = res.data;
			} catch (error) {
				console.log(error);
			}
		},

		async getTodaysEntry(selectedUser) {
			const params = {
				user: selectedUser ? selectedUser : this.$store.getters.userInfo.account.username,
				date: this.dateFormatted
			};

			try {
				this.entry1 = { description: "", step: 1 };
				this.entry2 = { description: "", step: 1 };
				this.entry3 = { description: "", step: 1 };
				let res = await this.$http.get(process.env.VUE_APP_API_URL + `/api/daily/entries`, { params });
				if (res.data.length > 0) {
					res.data.forEach((element, idx) => {
						switch (idx) {
							case 0:
								this.entry1 = element;
								break;
							case 1:
								this.entry2 = element;
								break;
							case 2:
								this.entry3 = element;
								break;
						}
					});
				}

				if (res.data.length > 0) {
					this.dailySubmitted = true;
				}
			} catch (e) {
				console.log("ERROR:", e);
			}
		},

		async getIncompleteEntries(selectedUser) {
			const params = {
				user: selectedUser ? selectedUser : this.$store.getters.userInfo.account.username
			};

			try {
				let res = await this.$http.get(process.env.VUE_APP_API_URL + `/api/daily/incompleteEntries`, {
					params
				});
				let incompleteItems = res.data;

				for (let index = 0; index < incompleteItems.length; index++) {
					const item = incompleteItems[index];

					const date1 = new Date(this.dateFormatted);
					const date2 = new Date(item.postedTime);

					const diffTime = Math.abs(date2 - date1);
					const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

					item.postedTime = diffDays;
				}

				incompleteItems = await incompleteItems.sort(function(a, b) {
					if (a.postedTime > b.postedTime) {
						return -1;
					}
					if (a.postedTime < b.postedTime) {
						return 1;
					}
				});

				this.incomplete = res.data;
			} catch (e) {
				console.log("ERROR:", e);
			}
		},

		async removeSelections() {
			for (let index = 0; index < this.incomplete.length; index++) {
				const item = this.incomplete[index];

				if (item.selected) {
					await this.remove(item.description);
				}
			}

			this.incomplete = this.incomplete.filter(function(obj) {
				return !obj.selected;
			});

			this.toast = {
				show: true,
				message: "Open items archived."
			};
		},

		async remove(description) {
			const params = {
				description: description,
				user: this.$store.getters.userInfo.account.username
			};

			try {
				await this.$http.put(process.env.VUE_APP_API_URL + `/api/daily/removeIncomplete`, params);
			} catch (e) {
				console.log("ERROR:", e);
			}
		},

		async completeSelections() {
			let hasCompletedTaskForToday = false;

			for (let index = 0; index < this.incomplete.length; index++) {
				const item = this.incomplete[index];

				if (item.selected) {
					await this.complete(item.entryId);
				}

				if (!hasCompletedTaskForToday) {
					hasCompletedTaskForToday =
						item.entryId === this.entry1?.entryId ||
						item.entryId === this.entry2?.entryId ||
						item.entryId === this.entry3?.entryId;
				}
			}

			this.incomplete = this.incomplete.filter(function(obj) {
				return !obj.selected;
			});

			this.toast = {
				show: true,
				message: "Open items marked completed."
			};

			if (hasCompletedTaskForToday) {
				this.bypassDialog = true;
				this.postCompleted(this.generatePreview);
			}
		},

		async complete(entryId) {
			const params = {
				user: this.$store.getters.userInfo.account.username,
				entryId: entryId,
				completedAt: new Date().getTime()
			};

			try {
				await this.$http.put(process.env.VUE_APP_API_URL + `/api/daily/completeOpenItems`, params);
			} catch (e) {
				console.log("ERROR:", e);
			}
		},

		async postDaily() {
			let slackRes = {};
			if (this.dailySubmitted) {
				let todaysPosts = this.paramList.filter(function(entry) {
					return entry.postedAt !== undefined;
				});
				const entry = todaysPosts.reduce((prev, current) =>
					prev.postedAt > current.postedAt ? prev : current
				);
				slackRes = await this.updateSlackMessage(entry.postedAt, this.preview);
			} else {
				slackRes = await this.sendToSlack(this.preview);
			}

			for (let index = 0; index < this.paramList.length; index++) {
				const entry = this.paramList[index];
				try {
					if (entry.projectId) {
						entry.postedAt = slackRes.data.ts ?? new Date();
						entry.unixDate = new Date().getTime();
						await this.$http.post(process.env.VUE_APP_API_URL + `/api/daily/entries`, entry);
					}

					this.toast = {
						show: true,
						message: "Thank you for your post. Have a great day!"
					};

					this.init();
				} catch (error) {
					console.log(error);
				}
			}

			this.confirmDialog = false;
		},

		getStepDiscription(step) {
			switch (step) {
				case 1:
				case "1":
					return ":rocket: ";
				case 2:
				case "2":
					return ":heads-down: ";
				case 3:
				case "3":
					return ":checkered_flag: ";
				default:
					return "";
			}
		},

		getProgressColor(daysSame) {
			switch (true) {
				case daysSame <= 2:
					return "green";
				case daysSame <= 5:
					return "yellow";
				case daysSame <= 8:
					return "orange";
				case daysSame > 8:
					return "red";
				default:
					return "grey";
			}
		},

		getProgressValue(daysSame) {
			return daysSame >= 14 ? 100 : (daysSame / 14) * 100;
		},

		async updateEntry(currentTask) {
			try {
				currentTask.description = currentTask.taskDesc;
				const res = await this.$http.put(process.env.VUE_APP_API_URL + `/api/daily/entries`, currentTask);
				console.log("UPDATED:", res);
			} catch (error) {
				console.log(error);
			}
		},

		async completeEntry(index) {
			let currentTask = this.taskList[index];

			if (currentTask.entryId !== undefined) {
				let slackMessage = "";

				this.taskList.forEach(entry => {
					try {
						console.log("ENTRY", entry);
						if (entry.completed) {
							slackMessage +=
								"~" + entry.position + ". " + entry.projectName + " - " + entry.taskDesc + "~\n";
						} else {
							if (entry.projectName) {
								slackMessage +=
									entry.position + ". " + entry.projectName + " - " + entry.taskDesc + "\n";
							}
						}
					} catch (error) {
						console.log(error);
					}
				});

				console.log("SLACK MESSAGE", slackMessage);
				console.log("POSTED AT", currentTask.postedAt);
				await this.completeToSlack(currentTask.postedAt, slackMessage);
			}
		},

		async sendToSlack(slackMessage) {
			try {
				var userJwt = this.parseJwt(this.userConfig.slackIdToken);
				var qs = require("qs");
				var data = qs.stringify({
					token: process.env.VUE_APP_SLACK_TOKEN,
					channel: process.env.VUE_APP_SLACK_CHANNEL,
					username: userJwt.name,
					icon_url: userJwt.picture,
					text: slackMessage
				});

				// remove application headers for slack API request
				const appHeader = { ...this.$http.defaults.headers.common };
				delete this.$http.defaults.headers.common["Authorization"];

				var config = {
					method: "post",
					url: "https://slack.com/api/chat.postMessage",
					headers: {
						"content-type": "application/x-www-form-urlencoded"
					},
					data: data
				};
				const res = await this.$http(config);

				//re-apply application headers
				this.$http.defaults.headers.common = appHeader;

				return res;
			} catch (e) {
				const status = e;
				console.error(`There was an error, HTTP status code: ${status}`);
			}
		},

		async updateSlackMessage(postedAt, slackMessage) {
			var qs = require("qs");
			var data = qs.stringify({
				token: process.env.VUE_APP_SLACK_TOKEN,
				channel: process.env.VUE_APP_SLACK_CHANNEL_ID,
				ts: postedAt,
				text: slackMessage
			});

			var config = {
				method: "post",
				url: "https://slack.com/api/chat.update",

				headers: {
					"content-type": "application/x-www-form-urlencoded"
				},
				data: data
			};

			try {
				// remove application headers for slack API request
				const appHeader = { ...this.$http.defaults.headers.common };
				delete this.$http.defaults.headers.common["Authorization"];

				const res = await this.$http(config);

				//re-apply application headers
				this.$http.defaults.headers.common = appHeader;

				return res;
			} catch (e) {
				console.log("ERROR:", e);
			}
		},

		async completeToSlack(postedAt, text) {
			console.log("IN COMPLETE", text);
			console.log("IN COMPLETE", postedAt);
			var qs = require("qs");
			var data = qs.stringify({
				token: process.env.VUE_APP_SLACK_TOKEN,
				channel: process.env.VUE_APP_SLACK_CHANNEL,
				ts: postedAt,
				text: text
			});

			console.log("DATA TO SEND", data);

			var config = {
				method: "post",
				url: "https://slack.com/api/chat.update",

				headers: {
					"content-type": "application/x-www-form-urlencoded"
				},
				data: data
			};

			console.log("SENDING");
			try {
				const res = await this.$http(config);
				console.log("RES: ", res);
			} catch (e) {
				console.log("ERROR:", e);
			}
		},

		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split("-");
			return `${month}/${day}/${year}`;
		},

		parseDate(date) {
			if (!date) return null;

			const [month, day, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},

		increaseCalendar() {
			let day = new Date(Date.parse(this.dateFormatted));
			day.setDate(day.getDate() + 1);

			this.dateFormatted = this.formatDate(day.toISOString().substr(0, 10));

			//call and pull and set everything!
		},

		decreaseCalendar() {
			let day = new Date(Date.parse(this.dateFormatted));
			day.setDate(day.getDate() - 1);

			this.dateFormatted = this.formatDate(day.toISOString().substr(0, 10));

			//call and pull and set everything!
		},

		handleDates() {
			const selectedDay = new Date(Date.parse(this.dateFormatted));
			const todayFormatted = this.formatDate(
				new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
			);
			const today = new Date(Date.parse(todayFormatted));

			if (selectedDay.getTime() != today.getTime()) {
				this.isToday = false;
				this.disableEntry = true;
			} else {
				this.isToday = true;
				this.disableEntry = false;
			}

			this.getTodaysEntry();
		},

		showProject(projectId) {
			this.detailsShown = true;
			this.selectedProjectId = projectId;
		},

		async getUsers() {
			try {
				const res = await this.$http.get(process.env.VUE_APP_API_URL + `/api/daily/users`);

				if (res.data) {
					// sort logged in user first
					let thisUserIndex = res.data.findIndex(user => {
						return user.email === this.$store.getters.userInfo.account.username;
					});
					let sortedUsers = this.arrayMove(res.data, thisUserIndex, 0);
					this.users = sortedUsers;
				}
				return res;
			} catch (error) {
				console.log("ERROR:", error);
				return false;
			}
		},

		arrayMove(array, from, to) {
			return array.map((item, i) =>
				i === to
					? array[from]
					: i >= Math.min(from, to) && i <= Math.max(from, to)
					? array[i + Math.sign(to - from)]
					: item
			);
		},

		onInput(selection, entry) {
			switch (entry) {
				case 1:
					if (selection && selection.entryId) this.entry1 = selection;
					else this.entry1 = { description: selection, projectId: this.entryProjectId, step: 1 };
					break;
				case 2:
					if (selection && selection.entryId) this.entry2 = selection;
					else this.entry2 = { description: selection, projectId: this.entryProjectId, step: 1 };
					break;
				case 3:
					if (selection && selection.entryId) this.entry3 = selection;
					else this.entry3 = { description: selection, projectId: this.entryProjectId, step: 1 };
					break;
			}

			this.entryProjectId = null;
		},

		userSelected(user) {
			this.isUserSelf = this.$store.getters.userInfo.account.username === user;
			this.getIncompleteEntries(user);
			this.getTodaysEntry(user);
			this.selectedUser = user;
		},

		generatePreview() {
			let isAuthenticated = this.ensureSlackAuth();
			if (isAuthenticated) {
				const paramList = [
					{
						entryId: this.entry1.entryId ?? null,
						projectId: this.entry1.projectId,
						projectName: this.projectList.find(p => p.projectId === this.entry1.projectId)
							?.projectName,
						position: 1,
						description: this.entry1.description,
						completed: this.entry1.completed ?? false,
						step: this.entry1.step ?? 1,
						user: this.$store.getters.userInfo.account.username,
						postedAt: this.entry1.postedAt
					},
					{
						entryId: this.entry2.entryId ?? null,
						projectId: this.entry2.projectId,
						projectName: this.projectList.find(p => p.projectId === this.entry2.projectId)
							?.projectName,
						position: 2,
						description: this.entry2.description,
						completed: this.entry2.completed ?? false,
						step: this.entry2.step ?? 0,
						user: this.$store.getters.userInfo.account.username,
						postedAt: this.entry2.postedAt
					},
					{
						entryId: this.entry3.entryId ?? null,
						projectId: this.entry3.projectId,
						projectName: this.projectList.find(p => p.projectId === this.entry3.projectId)
							?.projectName,
						position: 3,
						description: this.entry3.description,
						completed: this.entry3.completed ?? false,
						step: this.entry3.step ?? 0,
						user: this.$store.getters.userInfo.account.username,
						postedAt: this.entry3.postedAt
					}
				];

				let slackMessage = "";

				paramList.forEach(entry => {
					try {
						if (entry.completed) {
							slackMessage +=
								"~" +
								entry.position +
								". " +
								entry.projectName +
								" - " +
								this.linkSafeDescription(entry.description) +
								"~\n";
						} else {
							if (entry.description && entry.projectName) {
								slackMessage +=
									entry.position +
									". " +
									entry.projectName +
									" - " +
									this.getStepDiscription(entry.step) +
									entry.description +
									"\n";
							}
						}
					} catch (error) {
						console.log(error);
					}
				});

				this.preview = slackMessage;
				this.paramList = paramList;
				this.confirmDialog = !this.bypassDialog;
			}
		},

		linkSafeDescription(description) {
			if (description && this.isValidURL(description)) {
				let parts = description.split("/");
				let shortDesc = parts[parts.length - 1];
				let formattedDesc = `<${description}|${shortDesc}>`;
				return formattedDesc;
			} else {
				return description;
			}
		},

		isValidURL(string) {
			var res = string.match(
				/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
			);
			return res !== null;
		},

		async ensureSlackAuth() {
			try {
				const params = {
					user: this.$store.getters.userInfo.account.name,
					email: this.$store.getters.userInfo.account.username
				};
				const res = await this.$http.post(process.env.VUE_APP_API_URL + `/api/daily/userconfig`, params);
				this.userConfig = res.data.userConfig;

				if (res.data.userConfig.slackToken == null || res.data.userConfig.slackToken.length <= 0) {
					if (this.$store.getters.slackConfig && this.$store.getters.slackConfig.access_token) {
						return true;
					} else {
						let host = "https://slack.com/openid/connect/authorize";
						let clientId = "118723354103.2920960899910";
						let state = this.$store.getters.userInfo.uniqueId;
						let nounce = "14665";
						let redirectUri = encodeURI(`${process.env.VUE_APP_API_URL}/Daily123`);
						window.location = `${host}?response_type=code&scope=openid%20profile%20email&client_id=${clientId}&state=${state}&nounce=${nounce}&redirect_uri=${redirectUri}`;
					}
				}
			} catch (error) {
				console.log("ERROR:", error);
			}
		},

		parseJwt(token) {
			var base64Url = token.split(".")[1];
			var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			var jsonPayload = decodeURIComponent(
				atob(base64)
					.split("")
					.map(function(c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);

			return JSON.parse(jsonPayload);
		},

		copyToClipboard() {
			let input = document.getElementById("previewText");
			let text = input.innerText;
			if (window.clipboardData && window.clipboardData.setData) {
				// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
				return window.clipboardData.setData("Text", text);
			} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
				var textarea = document.createElement("textarea");
				textarea.textContent = text;
				textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
				document.body.appendChild(textarea);
				textarea.select();
				try {
					return document.execCommand("copy"); // Security exception may be thrown by some browsers.
				} catch (ex) {
					console.warn("Copy to clipboard failed.", ex);
					return prompt("Copy to clipboard: Ctrl+C, Enter", text);
				} finally {
					this.toast = {
						show: true,
						message: "Post copied to clipboard."
					};
					document.body.removeChild(textarea);
				}
			}
		},

		postCompleted(callback) {
			this.init();
			setTimeout(callback, 2000);
			setTimeout(this.postDaily, 3000);
			setTimeout(() => {
				this.bypassDialog = false;
			}, 4000);
		},

		async init() {
			const self = this;
			const msalInstance = new PublicClientApplication(this.msalConfig);
			const account = msalInstance.getAllAccounts()[0];
			const accessTokenRequest = {
				scopes: ["api://96d2671a-79de-473c-9de2-08b84b6ec545/access_as_user"],
				account: account
			};

			msalInstance
				.acquireTokenSilent(accessTokenRequest)
				.then(function(accessTokenResponse) {
					let token = accessTokenResponse.accessToken;
					self.$store.dispatch("saveToken", token).catch(error => {
						console.error(`error: ${error}`);
					});

					self.$http.defaults.headers.common = {
						Authorization: `Bearer ${token}`
					};

					self.getUsers();
					self.getProjectsList();
					self.getIncompleteEntries();
					self.handleDates();

					if (self.$route.query.code === undefined) {
						self.ensureSlackAuth();
					}
				})
				.catch(function(error) {
					console.log(error);
					if (error instanceof InteractionRequiredAuthError) {
						// fallback to interaction when silent call fails
						return msalInstance.acquireTokenRedirect(accessTokenRequest);
					}
				});
		}
	},
	async mounted() {
		this.init();

		let code = this.$route.query.code;
		let state = this.$route.query.state;

		// verify state before acquiring user token
		if (this.$store.getters.userInfo.uniqueId === state && code) {
			var qs = require("qs");
			var data = qs.stringify({
				code: code,
				client_id: "118723354103.2920960899910",
				client_secret: process.env.VUE_APP_SLACK_SECRET,
				redirect_uri: encodeURI(`${process.env.VUE_APP_API_URL}/Daily123`)
			});

			var config = {
				method: "post",
				url: "https://slack.com/api/openid.connect.token",
				headers: {
					"content-type": "application/x-www-form-urlencoded"
				},
				data: data
			};

			const res = await this.$http(config);

			if (res && res.data && res.data.access_token) {
				if (res.data.id_token) {
					let token = res.data.access_token;
					let slackIdentity = this.parseJwt(res.data.id_token);
					try {
						const params = {
							email: this.$store.getters.userInfo.account.username,
							slackToken: token,
							slackId: slackIdentity.sub,
							slackIdToken: res.data.id_token
						};
						await this.$http.post(process.env.VUE_APP_API_URL + `/api/daily/slackconfig`, params);
					} catch (error) {
						console.log("ERROR:", error);
					}
				}

				this.$store.dispatch("setSlackConfig", res.data).catch(error => {
					console.error(`error: ${error}`);
				});
			}
		}
	}
};
</script>

<style lang="scss">
.primary-container {
	display: flex;
	flex-flow: column;
	height: fit-content;
}

.primary-container .entry-container {
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
}

.primary-container .past-items-container {
	flex: 1 1 auto;
}

.past-items {
	overflow: auto;
}

.date-picker {
	width: fit-content;
	margin: auto;
}

.v-snack__content {
	font-size: initial;
}

.mobile-div {
	display: list-item;
	list-style: none;
	height: 24px;
}
</style>
