<template>
	<div style="height:100%">
		<div style="height:inherit">
			<v-container fluid class="sidebar-container pt-4" style="background-color: white;">
				<v-row v-if="$store.getters.isMobile">
					<v-col align="center" justify="center">
						<v-tooltip top v-if="!collapsed">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									class="mobile-toggle"
									small
									icon
									v-bind="attrs"
									v-on="on"
									@click="collapsed = true"
								>
									<v-icon>mdi-arrow-collapse-vertical</v-icon>Collapse
								</v-btn>
							</template>
							<span>Collapse</span>
						</v-tooltip>
						<v-tooltip top v-else>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									class="mobile-toggle"
									small
									icon
									v-bind="attrs"
									v-on="on"
									@click="collapsed = false"
								>
									<v-icon>mdi-arrow-expand-vertical</v-icon>Project Selection
								</v-btn>
							</template>
							<span>Expand</span>
						</v-tooltip>
					</v-col>
				</v-row>

				<v-row v-if="(!collapsed || !$store.getters.isMobile) && userList.length > 0">
					<v-col align="center" justify="center">
						<v-btn-toggle v-model="buttonSelected" mandatory>
							<v-btn x-large @click="indClick" class="">
								<h3>People</h3>
							</v-btn>
							<v-btn x-large @click="indSelection = false">
								<h3>Projects</h3>
							</v-btn>
						</v-btn-toggle>
					</v-col>
				</v-row>

				<v-row v-if="!collapsed || !$store.getters.isMobile">
					<Individuals v-if="indSelection" :userList="userList" @userSelected="emitSelectedUser" />
					<Projects v-else @selection="emitProjectSelection" />
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
import Individuals from "@/components/group_info/Individuals.vue";
import Projects from "@/components/group_info/Projects.vue";
export default {
	components: {
		Individuals,
		Projects
	},
	name: "Sidebar",
	props: {
		userList: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			buttonSelected: 0,
			indSelection: true,
			collapsed: true
		};
	},
	methods: {
		emitProjectSelection(projectId) {
			this.$emit("projectSelection", projectId);
		},

		indClick() {
			this.indSelection = true;
			this.$emit("indSelection");
		},

		emitSelectedUser(selectedUser) {
			this.$emit("userSelected", selectedUser);
		}
	}
};
</script>

<style lang="scss">
.package-search-form {
	.v-select__slot {
		width: 24rem !important;
	}
}

.sidebar-container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	overflow: hidden;
}

.mobile-toggle {
	z-index: 9999;
}
</style>
